<template>
  <div>
    <div
      v-if="$route.query && $route.query.user_id && is_add"
      class="d-flex flex-row-reverse"
    >
      <b-button
        @click="onClickPermission"
        class="mb-1"
        variant="success"
        size="sm"
        v-b-toggle.sidebar-backdrop
        >Permission</b-button
      >
    </div>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="First Name">
              <b-form-input
                v-model="form.first_name"
                id="input-default"
                placeholder="First Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Last Name">
              <b-form-input
                v-model="form.last_name"
                id="input-default"
                placeholder="Last Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Contact No">
              <b-form-input
                type="number"
                v-model="form.mobile_number"
                id="input-default"
                placeholder="Contact No"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Email">
              <b-form-input
                v-model="form.email"
                id="input-default"
                placeholder="Email"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Password">
              <b-form-input
                v-model="form.password"
                id="input-default"
                placeholder="Password"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4"
            ><b-form-group label="User Role">
              <v-select
                :clearable="false"
                v-model="form.role_id"
                label="role_name"
                value="role_id"
                :reduce="(role) => role.role_id"
                placeholder="Select Role"
                :options="rolesList"
              /> </b-form-group
          ></b-col>

          <b-col cols="12" md="4">
            <b-form-group label="User Status">
              <b-form-checkbox v-model="form.user_is_active" switch>{{
                form.user_is_active == true ? "Active" : "Disabled"
              }}</b-form-checkbox>
            </b-form-group>
          </b-col>
          <!-- 
          <b-col cols="12" md="12">
            <div>
              <span
                ><b>View:</b> Access to view data.Cannot do add & update!<br
              /></span>
              <span><b>Add:</b> Access to add & view data.<br /></span>
              <span><b>Update:</b> Access to update & view data.</span>
              <div class="container">
                <div class="row flex-nowrap overflow-auto">
                  <b-list-group horizontal>
                    <b-list-group-item
                      variant="primary"
                      v-for="(item, index) in form.role_permission"
                      :key="index"
                      v-if="
                        item.subject != 'home' &&
                        item.subject != 'organizationlist'
                      "
                      ><h5>
                        <b>{{ item.name }}</b>
                      </h5>

                      <b-form-checkbox-group
                        v-model="item.action"
                        :options="item.optionsPermission"
                      ></b-form-checkbox-group>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>
            </div>
          </b-col> -->

          <b-col
            v-if="form.is_super_admin != 'Y'"
            class="mt-3"
            md="6"
            offset-md="3"
          >
            <b-button block variant="primary" @click="onSubmit"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-sidebar id="sidebar-backdrop" backdrop shadow right>
      <template #default="{ hide }">
        <div class="text-center">
          <h4 class="heading" id="sidebar-no-header-title">
            <b>PERMISSIONS</b>
          </h4>
        </div>
        <div class="m-1">
          <span
            ><b>View:</b> Access to view data.Cannot do add & update!<br
          /></span>
          <span><b>Add:</b> Access to add & view data.<br /></span>
          <span><b>Update:</b> Access to update & view data.</span>
        </div>
        <b-list-group>
          <b-list-group-item v-for="(per, index) in permissionList" :key="index"
            ><b>{{ per.name }}</b>
            <div class="d-flex flex-row">
              <b-form-checkbox
                class="m-1"
                v-for="(child, indxChild) in per.action"
                :key="indxChild"
                name="check-button"
                switch
                v-model="child.status"
              >
                <b>{{ child.text }} </b>
              </b-form-checkbox>
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-button class="mb-1" @click="onSavePermissions" block variant="dark"
          >Save</b-button
        >
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import vSelect from "vue-select";

import {
  AddUpdateUserService,
  GetUserListService,
  GetRolesListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UserService } from "@/apiServices/storageService";
export default {
  data() {
    return {
      form: {
        org_id: null,
        user_id: null,
        first_name: null,
        last_name: null,
        mobile_number: null,
        email: null,
        user_name: null,
        password: null,
        role_id: null,
        user_is_active: true,
        is_super_admin: null,
        role_permission: null,
      },

      rolesList: [],
      permissionList: [],
    };
  },
  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  components: {
    vSelect,
  },
  async beforeMount() {
    if (this.$route.query && this.$route.query.user_id) {
      await this.getUserById(this.$route.query.user_id);
    } else {
      // let givenPermission = UserService.getUserPermissions();
      // for (let i of givenPermission) {
      //   if (i.subject && i.subject != "organizationlist") {
      //     let optionsPermission = [];
      //     i.action = i.permission ? i.permission : [];
      //     delete i.permission;
      //     if (i.action.includes("read")) {
      //       optionsPermission.push({ text: "View", value: "read" });
      //     }
      //     if (i.action.includes("create")) {
      //       optionsPermission.push({ text: "Add", value: "create" });
      //     }
      //     if (i.action.includes("update")) {
      //       optionsPermission.push({ text: "Update", value: "update" });
      //     }
      //     i["optionsPermission"] = optionsPermission;
      //     this.form.role_permission.push(i);
      //   }
      // }
    }

    this.getRoleList();
  },
  methods: {
    async onSavePermissions() {
      let tempPermssions = [];
      this.form.role_permission = null;
      for await (let i of this.permissionList) {
        let obj = {
          subject: i.subject,
          name: i.name,
          action: [],
        };
        for await (let p of i.action) {
          if (p.status == true) {
            obj.action.push(p.value);
          }
        }
        tempPermssions.push(obj);
      }

      this.form.role_permission = JSON.stringify(tempPermssions);
      this.onSubmit();
    },

    onClickPermission() {
      if (this.form.role_permission) {
        let parsedData = JSON.parse(this.form.role_permission);
        for (let i of parsedData) {
          let permissionIndex = this.permissionList.findIndex(
            (x) => x.subject == i.subject
          );

          if (permissionIndex >= 0) {
            this.permissionList[permissionIndex].action.map((y) => {
              console.log(y, "=", i.action, i.action.includes(y.value));
              if (i.action.includes(y.value)) {
                y.status = true;
              }
            });
          }
        }
      }
    },
    async onSubmit() {
      try {
        // for (let i of this.form.role_permission) {
        //   if (i.optionsPermission) {
        //     delete i.optionsPermission;
        //   }
        // }
        this.form.user_name = this.form.email;
        this.form.user_is_active = this.form.user_is_active == true ? "Y" : "N";
        const response = await AddUpdateUserService(this.form);
        if (response.data.status) {
          this.$router.push({
            name: "userlist",
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserById(user_id) {
      try {
        this.permissionList = [];
        let tempPermissions = this.form.role_permission;
        const response = await GetUserListService({
          user_id: user_id ? user_id : 0,
        });

        if (response.data.status) {
          if (response.data.Records && response.data.Records.data) {
            this.permissionList = response.data.permissionArray;
            let userArray = response.data.Records.data[0];
            Object.keys(userArray).map((x) => {
              this.form[x] = userArray[x];

              // if (x == "role_permission") {
              //   if (userArray[x]) {
              //     let parsedData = JSON.parse(userArray[x]);
              //     let array = [];

              //     for (let i of parsedData) {
              //       if (i.action.length) {
              //         let optionsPermission = [];
              //         i.action = i.action ? i.action : [];

              //         if (i.action.includes("read")) {
              //           optionsPermission.push({ text: "View", value: "read" });
              //         }
              //         if (i.action.includes("create")) {
              //           optionsPermission.push({
              //             text: "Add",
              //             value: "create",
              //           });
              //         }
              //         if (i.action.includes("update")) {
              //           optionsPermission.push({
              //             text: "Update",
              //             value: "update",
              //           });
              //         }

              //         i["optionsPermission"] = optionsPermission;
              //         array.push(i);
              //       }
              //     }

              //     this.form[x] = array;
              //     // console.log(array, "array");
              //   } else {
              //     this.form[x] = tempPermissions;
              //   }
              // }
              if (x == "user_is_active") {
                this.form[x] = userArray[x] == "Y" ? true : false;
              }
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getRoleList() {
      try {
        const response = await GetRolesListService({
          limit: 1000,
        });

        if (response.data.status) {
          if (response.data.Records && response.data.Records.data) {
            this.rolesList = response.data.Records.data;
            this.rolesList = this.rolesList.filter((item) => item.role_id != 3);
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
